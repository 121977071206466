<template>
  <div class="page">
    <div class="box">
      <div class="in_box">
        <!-- 事件类型 -->
        <div class="type_box">
          <div class="title">事件类型<span style="color: red">*</span></div>
          <div class="choise" @click="show = true">{{ eventTypeData }}</div>
        </div>
        <!-- 事件地点 -->
        <div class="place_box">
          <div class="title">事件地点<span style="color: red">*</span></div>
          <div class="fill_in_box">
            <input
              type="text"
              v-model="form.eventPlace"
              placeholder="请填写事件地点"
            />
          </div>
        </div>
        <!-- 事件描述 -->
        <div class="describe_box">
          <div class="describe_in_box">
            <div class="top_box">
              <van-field
                style="background: transparent; height: 100%"
                v-model="form.eventDescription"
                type="textarea"
                maxlength="200"
                autosize
                row="3"
                placeholder="请输入描述，200字以内..."
                show-word-limit
              />
            </div>
            <div class="bottom_box">
              <v-upload
                class="oItem-b_upload"
                :imgUrl.sync="form.proposalPic"
                :activityPicture.sync="form.proposalPic"
                :maxCount="3"
                ref="load"
              ></v-upload>
            </div>
          </div>
        </div>
        <!-- 文字提示 -->
        <div class="message_box">
          <p>1.图片请保存清晰</p>
          <p>2.图片应能反映事发地点特征</p>
        </div>
        <!-- 当前住址 -->
        <div class="now_place">
          <div class="now_place_in_box">
            <div class="left_box">事件发生坐标:</div>
            <div class="center_box">
              <p>{{ this.$route.query.place || nowPlace }}</p>
            </div>
            <div class="right_box">
              <div class="edit_btn" @click="toEdid('editPlace')">编辑</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="btn_box">
      <div class="btn" @click="submit">提交</div>
    </div>
    <!-- 事件类型 -->
    <v-picker
      :columns="eventTypeList"
      :valueShow="show"
      :valueKey="eventKey"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import {
  uploadingSnapshotUrl,
  incidentTypeListUrl,
  userAssetListUrl,
} from "./api.js";
var moment = require("moment");
export default {
  name: "reportForRepairCreate",
  data() {
    return {
      show: false,
      eventKey: "text",
      eventTypeData: "请点击选择事件类型",
      form: {
        proposalPic: "",
        incidentType: null,
        eventPlace: "",
        eventDescription: "",
      },

      timer: null,
      eventTypeList: [],

      nowPlace: "",

      latitude: "",
      longitude: "",
      errorPositionTxt: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    console.log(JSON.parse(sessionStorage.getItem("createForm")));
    if (JSON.parse(sessionStorage.getItem("createForm"))) {
      this.form.proposalPic = JSON.parse(
        sessionStorage.getItem("createForm")
      ).proposalPic;
      this.form.incidentType = JSON.parse(
        sessionStorage.getItem("createForm")
      ).incidentType;
      this.form.eventPlace = JSON.parse(
        sessionStorage.getItem("createForm")
      ).eventPlace;
      this.form.eventDescription = JSON.parse(
        sessionStorage.getItem("createForm")
      ).eventDescription;
      this.eventTypeData = this.form.incidentType || "请点击选择事件类型";
    }
  },
  mounted() {
    this.getEventTypeList();
    this.getPlace();
  },
  methods: {
    toEdid(path) {
      //跳转编辑住址页面
      sessionStorage.setItem("createForm", JSON.stringify(this.form));
      this.$router.replace({
        name: path,
        query: {
          path: this.$route.name,
        },
      });
    },
    getPlace() {
      //获取当前用户使用住址
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${userAssetListUrl}`, params).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            if (item.isChosen == 1) {
              this.nowPlace = item.houseName;
            }
          });
        }
      });
    },
    getEventTypeList() {
      //获取事件类型列表
      let params = {
        workTypeName: "报事报修",
      };
      this.$axios
        .get(`${incidentTypeListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.records.forEach((item) => {
              item.text = item.incidentName;
              this.eventTypeList.push(item);
            });
            console.log(this.eventTypeList);
          }
        });
    },
    async submit() {
      //提交按钮
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.proposalPic) {
        this.$toast("请上传图片");
        return;
      }
      if (this.form.incidentType == "") {
        this.$toast("事件类型不能为空！");
        return;
      }
      if (this.form.eventPlace == "") {
        this.$toast("事件地点不能为空！");
        return;
      }
      if (this.form.eventDescription == "") {
        this.$toast("事件描述不能为空！");
        return;
      }
      this.postData();
    },
    change() {},
    postData() {
      let params = {
        workTypeName: "报事报修",
        tenantId: this.tenantId,
        userId: this.userId,
        houseId: this.houseId,
        incidentName: this.form.incidentType,
        eventPlace: this.form.eventPlace,
        eventDescription: this.form.eventDescription,
        eventPicture: this.form.proposalPic,
        latitude: localStorage.getItem("latitude"),
        longitude: localStorage.getItem("longitude"),
        houseName: this.$route.query.place || this.nowPlace,
      };
      this.$axios.post(`${uploadingSnapshotUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast(res.msg);
          sessionStorage.removeItem("createForm");
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "reportForRepairSuccess",
            });
          }, 200);
        } else {
        }
      });
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      console.log(value.text);
      this.eventTypeData = value.text;
      this.form.incidentType = value.text;
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-top: 14px;
  box-sizing: border-box;

  .btn_box {
    width: 100%;
    height: 66px;
    padding: 0 100px;
    box-sizing: border-box;
    position: fixed;
    bottom: 120px;
    left: 0;

    .btn {
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
      border-radius: 10px;
      text-align: center;
      line-height: 66px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .box {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    // height: 686px;

    .in_box {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding-top: 20px;
      padding-bottom: 50px;

      .now_place {
        width: 100%;
        padding: 0 24px;
        box-sizing: border-box;

        .now_place_in_box {
          width: 100%;
          height: 100%;
          display: flex;

          .left_box {
            color: #ccc;
            font-size: 30px;
          }
          .center_box {
            flex: 1;
            color: #ccc;
            font-size: 30px;
          }
          .right_box {
            width: 120px;
            height: auto;
            position: relative;

            .edit_btn {
              width: 100px;
              height: 50px;
              background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
              line-height: 50px;
              text-align: center;
              font-size: 24px;
              border-radius: 15px;
              color: #ffffff;
              font-weight: 600;
              position: absolute;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }

      .message_box {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 24px 30px;

        p {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 10px;
        }
      }

      .describe_box {
        width: 100%;
        padding: 0 24px;
        box-sizing: border-box;

        .describe_in_box {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          background: rgba(0, 0, 0, 0.04);
          overflow: hidden;

          .top_box {
            width: 100%;
            // height: 200px;
          }

          .bottom_box {
            width: 100%;
            height: calc(100% - 200px);
            padding: 0 32px;
            display: flex;

            .oItem-b_upload {
              margin: 32px 0 16px;

              ::v-deep .van-uploader__preview {
                border: 2px dashed #eee;
              }

              ::v-deep .van-uploader__preview-image {
                width: 160px !important;
                height: 160px !important;
                box-sizing: border-box;
              }

              ::v-deep .van-uploader__upload {
                width: 160px !important;
                height: 160px !important;
                box-sizing: border-box;
              }

              ::v-deep .van-uploader__upload {
                margin: 0;
              }
            }
          }
        }
      }

      .place_box {
        width: 100%;
        height: 80px;
        line-height: 80px;
        box-sizing: border-box;
        display: flex;
        padding: 0 24px;

        .title {
          width: 180px;
          font-size: 32px;
          color: #333333;
        }

        .fill_in_box {
          flex: 1;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);

          input {
            width: 100%;
            height: 100%;
          }
        }
      }

      .type_box {
        width: 100%;
        height: 80px;
        line-height: 80px;
        box-sizing: border-box;
        display: flex;
        padding: 0 24px;

        .title {
          width: 180px;
          font-size: 32px;
          color: #333333;
        }

        .choise {
          flex: 1;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
